import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';

import ProjectManager from 'utils/ProjectManager';
import config from './config';
import App from './App';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'admin',
        endpoint: config.API_URL,
        region: config.REGION,
        custom_header: async () => (
          {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'x-api-key': config.API_KEY,
            version: 1,
            project: ProjectManager.getCurrentProject(),
          }
        ),
      },
    ],
  },
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
