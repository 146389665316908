import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  BooleanInput,
  DateField,
  TextInput,
  Filter,
  ShowButton,
  DateInput,
  SelectArrayInput,
  FunctionField,
  Button, Pagination, SelectInput,
} from 'react-admin';
import List from 'components/List';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import Loader from 'components/Loader';
import { getChoicesList } from 'utils/common';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { getDefaultDataGripStyles } from '../../utils/common';
import OrdersListActions from './OrdersListActions';
import dataProvider from '../../providers/DataProvider';
import { EmptyTable } from '../../components/EmptyTable';
import ProjectManager from '../../utils/ProjectManager';
import { DESCRIPTORS, PROJECT_WITH_LIFETIME_EXPORT_LOGIC } from '../../providers/ServerConstantsProvider/constants';

const rowStyle = (record) => ({
  borderLeftColor: record.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
});
const getCountriesChoices = (countries) => {
  const choices = getChoicesList(Object.values(countries));
  choices.unshift({ id: null, name: 'Without country' });

  return choices;
};

const fromBinary = (encoded) => {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes;
};

const handleDownloadsRequest = async (record) => {
  try {
    dataProvider
      .downloadOrderTemplate({ external_id: record.external_id })
      .then((res) => {
        const fileURL = window.URL.createObjectURL(new Blob([fromBinary(res.data)]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${record.external_id}.html`,
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};

const downloadReciptHTML = async (record) => {
  await handleDownloadsRequest(record);
};

const OrderFilter = (props) => {
  const { countries, ...rest } = props;

  return (
    <Filter {...rest} style={{ marginTop: '5px', marginBottom: '10px' }}>
      <TextInput source="email" resettable alwaysOn />
      <TextInput source="external_id" resettable alwaysOn />
      <DateInput label="Processed from" source="date_from" resettable alwaysOn />
      <DateInput label="Processed to" source="date_to" resettable alwaysOn />
      <SelectInput
        label="Descriptor"
        source="descriptor"
        choices={DESCRIPTORS}
        resettable
        alwaysOn
      />
      <If condition={PROJECT_WITH_LIFETIME_EXPORT_LOGIC.includes(ProjectManager.getCurrentProject())}>
        <BooleanInput label="Lifetime" source="lifetime" resettable alwaysOn />
      </If>
      <SelectArrayInput
        source="country"
        choices={getCountriesChoices(countries)}
        allowEmpty
        emptyValue=""
        resettable
        alwaysOn
      />
    </Filter>
  );
};

const Orders = (props) => {
  const { serverConstants } = props;
  const classes = getDefaultDataGripStyles();

  return (
    <Choose>
      <When condition={serverConstants?.countries}>
        <List
          rowStyle={rowStyle}
          dataGridClasses={{ headerRow: classes.headerRow }}
          filters={<OrderFilter countries={serverConstants.countries.items} />}
          actions={<OrdersListActions />}
          pagination={<Pagination {...props} limit={<EmptyTable />} />}
          empty={false}
          {...props}
        >
          <TextField label="Id" source="id" />
          <TextField label="External Id" source="external_id" />
          <TextField label="Amount" source="amount" />
          <TextField label="Currency" source="currency" />
          <TextField label="User Email" source="email" />
          <TextField label="Status" source="status" />
          <DateField label="Create date" source="created_at" />
          <FunctionField
            label="Country"
            render={(record) => record.country || record.autodetect_country}
          />;
          <FunctionField
            label="Descriptor"
            render={(record) => {
              const match = record?.descriptor?.match(/\[([^\]]+)\]/);
                return match ? match[1] : record.descriptor;
          }}
          />;
          <ShowButton />
          <FunctionField
            render={(record) => (
              <Button
                onClick={() => {
                  downloadReciptHTML(record);
                }}
                label="Download receipt"
              >
                <SaveAltIcon />
              </Button>
            )}
          />

        </List>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

OrderFilter.propTypes = {
  countries: PropTypes.object,
};

OrderFilter.defaultProps = {
  countries: {},
};

Orders.propTypes = {
  serverConstants: PropTypes.object,
};

Orders.defaultProps = {
  serverConstants: {},
};

export default withServerConstants(Orders);
