import React, { useEffect, useState } from 'react';
import {
  useEditController,
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  BooleanField,
} from 'react-admin';

import { UserActions } from './UserActions';
import EditToolbar from '../../components/EditToolbar';
import ReceiptForm from '../../components/ReceiptForm';
import Loader from '../../components/Loader';
import { getPaymentMethod } from './helpers';

export const UserEdit = (props) => {
  const { loading, record } = useEditController(props);
  const blockForm = !!(!loading && !['web', 'WEB', null, undefined, 3].includes(record.payment_platform));
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const adaptDataForReceiptForm = (purchaseData, recordData) => purchaseData.map((subscription) => ({
      is_subscription: true,
      product_name: subscription.product?.name,
      product_id: subscription.product?.id,
      discount_type: subscription.discount?.discount_type,
      discount_granted_at: subscription.discount?.discount_granted_at,
      product_code: subscription.product_code,
      branch_name: recordData?.client_user_info?.branch_name,
      flow_name: recordData?.client_user_info?.flow_name,
      link_id: recordData?.client_user_info?.link_id,
    }));

  useEffect(() => {
    if (!loading && record?.id) {
      getPaymentMethod(record.id).then((data) => {
        setSubscriptions(data);
        setSubscriptionsLoading(false);
      });
    }
  }, [loading, record]);

  return (
    <Choose>
      <When condition={!loading}>
        <Edit actions={<UserActions isEdit loading={blockForm} />} {...props} blockForm={blockForm}>
          <SimpleForm toolbar={<EditToolbar blockForm={blockForm} />}>
            <If condition={!loading && record.error_message}>
              <h2 style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                App database error: user not found
              </h2>
            </If>
            <If condition={blockForm}>
              <h2 style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                The user no longer processes on the WEB platform
              </h2>
            </If>
            <BooleanField label="Soft deleted" source="is_suspended" />
            <BooleanInput label="Is paid" source="is_paid" disabled={blockForm} />
            <BooleanField
              label="Lifetime"
              source="lifetime"
              disabled={blockForm}
            />
            <TextInput
              label="lifetime date"
              source="lifetime_timestamp"
              type="text"
              disabled
              format={(value) => value ? new Date(value).toISOString().substring(0, 10) : null}
            />
            <TextInput
              label="lifetime expired at"
              source="lifetime_expired_at"
              type="text"
              disabled
              format={(value) => value ? new Date(value).toISOString().substring(0, 10) : null}
            />

            <TextInput label="Email" source="email" type="email" disabled={blockForm} />
            <BooleanField label="Is password set:" source="is_password" />
            <TextInput label="Payment platform" source="payment_platform" disabled />
            <div>
              <label style={{ fontWeight: 'bold' }}>Payment provider:</label>
              <div style={{ marginTop: '8px', marginBottom: '16px', fontSize: '18px' }}>
                {subscriptionsLoading && 'Loading...'}
                {!subscriptionsLoading && subscriptions.length > 0 && subscriptions[0].payment_provider}
                {!subscriptionsLoading && subscriptions.length === 0 && 'No data'}
              </div>
            </div>
            <If condition={!loading && record.orders}>
              <ReceiptForm orders={record.orders} />
            </If>
            <If condition={!loading && record.orders < 1}>
              {subscriptionsLoading ? (
                <div>Loading subscriptions...</div>
              ) : (
                  subscriptions.length > 0 && (
                  <ReceiptForm orders={adaptDataForReceiptForm(subscriptions, record)} />
                  )
              )}
            </If>
          </SimpleForm>
        </Edit>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

export default UserEdit;
