import React from 'react';
import './ReceiptForm.scss';

const ReceiptForm = (props) => {
  const data = [];
  props.orders.map((e) => data.push(
    <div className="ReceiptFormStyles">
      {e.is_subscription ? <p> Subscription </p> : <p> Additional purchase </p>}
      <ul>
        {e.is_subscription ? <li><b>Product name:</b> {e.product_name}</li> : null}
        {e.is_subscription ? <li><b>Product id:</b> {e.product_id}</li> : null}
        {(e.is_subscription && e.discount_type) ? <li><b>Discount type:</b> {e.discount_type}</li> : null}
        {
          ((e.is_subscription && e.discount_granted_at)
            ? <li><b>Discount granted at:</b> {e.discount_granted_at}</li> : null)
        }
        <li><b>Product code:</b> {e.product_code}</li>
        <li><b>Flow link :</b> {e.flow_link}</li>
        <li><b>Payment screen :</b> {e.payment_screen}</li>
        <li><b>Ab-test-name:</b> {e.ab_test_name}</li>
        {e.branch_name ? <li><b>Branch-name:</b> {e.branch_name}</li> : null}
        {e.flow_name ? <li><b>Flow-name:</b> {e.flow_name}</li> : null}
        {e.link_id ? <li><b>Link-id:</b> {e.link_id}</li> : null}
      </ul>
    </div>,
  ));
  return data;
};

export default ReceiptForm;
